const csv = "text/csv";
const png = "image/png";
const jpeg = "image/jpeg";
const pdf = "application/pdf";
const dmn = "application/dmn+xml";
const bpmn = "application/bpmn+xml";
const xls = "application/vnd.ms-excel";
const xlsx = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";

const fileTypes = {
    pdf, jpeg, png, csv, dmn, bpmn, xls, xlsx
}

export { fileTypes };