const columnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'Contract',
        columnKey: 'contract'
    },
    {
        columnName: 'Customer Name',
        columnKey: 'customerName'
    },
    {
        columnName: 'Elligible Amount',
        columnKey: 'pos'
    },
    {
        columnName: 'Payout Amount',
        columnKey: 'totalPayout'
    },
    {
        columnName: 'Adjustment',
        columnKey: 'adjustmentAmount'
    },
];

const columnData = [
    {
        "id": 1,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 7606978.0,
        "totalPayout": 54648.59,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 60,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }, {
        "id": 2,
        "contract": {
            "id": "TCFCE0310000012445051"
        },
        "customerName": "OSS CONSTRUCTION PRIVATE LIMITED",
        "pos": 1.0580258E7,
        "totalPayout": 76057.57,
        "bucket": null,
        "bucketStatus": null,
        "processInstanceKey": null,
        "state": null,
        "transactionID": 61,
        "adjustmentAmount": 0.0,
        "adjustment": {
            "id": null,
            "amount": 0.0,
            "reason": "",
            "remarks": "",
            "paymentPartyCalcId": null,
            "fileLocation": ""
        },
        "product": {
            "id": {
                "name": "CVC"
            }
        },
        "averageDailyBalance": null,
        "processingFees": null,
        "sanctionedAmount": null,
        "pdd": false,
        "finalPayout": null,
        "hundredPerPayout": null
    }
]

const invoiceColumnList = [
    {
        columnName: '#',
        columnKey: 'id'
    },
    {
        columnName: 'System Invoice',
        columnKey: 'systemInvoice'
    },
    {
        columnName: 'Agency Invoice',
        columnKey: 'agencyInvoice'
    },
    {
        columnName: 'Amount',
        columnKey: 'amount'
    },
    {
        columnName: 'State',
        columnKey: 'state'
    },
    {
        columnName: 'Invoice Date',
        columnKey: 'invoiceDate'
    },
    {
        columnName: 'Payout Month',
        columnKey: 'payoutMonth'
    },
    {
        columnName: 'UTR Number',
        columnKey: 'utrNumber'
    },
    {
        columnName: 'Payment Amount',
        columnKey: 'paymentAmount'
    },
    {
        columnName: 'Payment Date',
        columnKey: 'paymentDate'
    },
    {
        columnName: 'Download Invoice',
        columnKey: 'downloadInvoice'
    },
    {
        columnName: 'Upload Invoice',
        columnKey: 'uploadInvoice'
    },
    {
        columnName: 'Uploaded Invoice',
        columnKey: 'uploadedInvoice'
    },
];

const invoiceColumnData = [
    {
        "systemInvoice": "ABCD/1234/DE56",
        "agencyInvoice": "",
        "amount": 2400,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2450,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "EFGH/5678/AB90",
        "agencyInvoice": "999111",
        "amount": 4000,
        "state": "Telangan",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "May",
        "utrNumber": "147298",
        "paymentAmount": 4500,
        "paymentDate": "2024-04-30 12:09:20",
    },
    {
        "systemInvoice": "ABCD/1234",
        "agencyInvoice": "",
        "amount": 2420,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "123789",
        "paymentAmount": 2550,
        "paymentDate": "2024-04-30 12:09:20",
    },
]

export { columnList, columnData, invoiceColumnList, invoiceColumnData }