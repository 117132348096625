// package imports
import { Route } from 'react-router-dom';
// import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';
import UnificationHistoryView from 'pages/unificationList/unificationHistoryView';
import UnificationHistoryShow from 'pages/unificationList/unificationHistoryShow';
import { Suspense } from 'react';

const UnificationHistoryRoute = (
  <>
    <Route
      index
      path={routeName.unificationHistory}
      element={
        <PrivateRoute name={PrivateRouteConst.UNIFICATION_HISTORY}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <UnificationHistoryView />
          </Suspense>
        </PrivateRoute>
      }
    />
    <Route
      path={`${routeName.unificationHistory}/show/:id`}
      element={
        <PrivateRoute name={PrivateRouteConst.UNIFICATION_VIEW}>
          <UnificationHistoryShow />
        </PrivateRoute>
      }
    />
  </>
);

export default UnificationHistoryRoute;
