// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import resourceName from 'core/common/resourceName';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

// Lazy-loaded Component
const Augment8Dashboard = lazy(
  () => import('pages/augment8Dashboard/augment8Dashboard')
);
const DashboardRoute = (
  <Route
    path={routeName.dashboard}
    element={
      <PrivateRoute name={resourceName.augment8Dashboard}>
        <Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          <Augment8Dashboard />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default DashboardRoute;
