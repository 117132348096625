// Package Imports
import {
  BaseRecord,
  CrudFilters,
  HttpError,
  useTranslate,
} from '@refinedev/core';
import { useSelect, useSimpleList, useTable } from '@refinedev/antd';
import { Form, Button, Card, Row, Col } from 'antd';
import { useEffect, useState } from 'react';
// Project Imports
import DropdownInput from 'components/dropdown/dropDownInput';
import apiEndpoint from 'core/common/apiEndPoints';
import { FilterVariables, IPost } from 'pages/unificationList/interface';
import ComponentToRender from 'pages/unificationList/ComponentToRender';
import { useWatch } from 'antd/es/form/Form';
import statusDropdown from 'pages/unificationList/dropDownDummy';
import logger from 'core/utils/logger';
import scrollToTop from 'core/utils/scrollToTop';
import UnificationHistoryTable from 'pages/unificationList/unificationHistoryTable';
import getInitializationDate from 'core/utils/getInitializationDate';

export const Filter = ({
  formProps,
  setFilters,
  frequencyName,
  setFrequencyName,
}) => {
  const { form } = formProps;
  const t = useTranslate();
  const [showComponent, setShowComponent] = useState(null);
  const onReset = async () => {
    formProps?.form?.resetFields();
    setFilters([], 'replace');
    setFrequencyName('');
    setShowComponent(null);
  };

  const programId = useWatch('program', {
    form,
    preserve: true,
  });
  const frequencyId = useWatch('frequency', {
    form,
    preserve: true,
  });

  const programListProps = useSelect({
    dataProviderName: 'organizationDataProvider',
    resource: apiEndpoint.incentiveProgram,
    optionLabel: 'iprogram_name',
    optionValue: 'unique_id',
    meta: {
      type: 'select',
    },
  });

  const programOptionList = (programListProps as any)?.selectProps?.options;
  logger('Program Dropdown List', programOptionList);
  // Api call to get frequency list
  const { listProps, queryResult: frequencyQueryResult } = useSimpleList({
    resource: `${apiEndpoint.programConfigDetails}/?iprogram_id=${programId}`,
    dataProviderName: 'programDataProvider',
    queryOptions: { enabled: !!programId },
    pagination: {
      mode: 'off',
    },
  });
  const frequencyOptionList = (listProps?.dataSource as any)?.results;
  if (programId) {
    logger('Frequency Dropdown List', frequencyOptionList);
  }
  if (frequencyQueryResult?.error?.response?.data) {
    logger(
      'Frequency Dropdown List Error ::',
      frequencyQueryResult?.error?.response?.data
    );
  }
  // get unique frequency name from frequency list
  const uniqueFrequencyIds = new Set();
  const uniqueFrequencyOptionList = frequencyOptionList?.filter((item) => {
    if (!uniqueFrequencyIds.has(item.frequency_id)) {
      uniqueFrequencyIds.add(item.frequency_id);
      return true;
    }
    return false;
  });
  if (programId) {
    logger('Unique Frequency Dropdown List', uniqueFrequencyOptionList);
  }
  const { listProps: workflowData, queryResult: workflowQueryResult } =
    useSimpleList({
      resource: `${apiEndpoint.programConfigDetails}/?iprogram_id=${programId}&frequency_id=${frequencyId}`,
      dataProviderName: 'programDataProvider',
      queryOptions: { enabled: !!frequencyId && !!programId },
      pagination: {
        mode: 'off',
      },
    });
  const workflowList = (workflowData?.dataSource as any)?.results;
  if (programId && frequencyId) {
    logger('Workflow Dropdown List', workflowList);
  }
  if (workflowQueryResult?.error?.response?.data) {
    logger(
      'Workflow Dropdown List Error ::',
      workflowQueryResult?.error?.response?.data
    );
  }
  const onSelectProgram = () => {
    setFrequencyName('');
    setShowComponent(null);
    formProps?.form?.setFieldsValue({
      frequency: null,
      date: null,
      status: null,
      workflow: null,
    });
  };
  const onSelectFrequency = () => {
    setShowComponent(null);
    formProps?.form?.setFieldsValue({
      date: null,
      status: null,
      workflow: null,
    });
  };
  function extractFrequencyName(dataValue, dataArray) {
    const matchingItem = dataArray?.find(
      (item) => item.frequency_id === dataValue
    );
    return matchingItem ? matchingItem.frequency_name : null;
  }
  useEffect(() => {
    let frequencyNameData = extractFrequencyName(
      frequencyId,
      uniqueFrequencyOptionList
    );
    frequencyNameData = frequencyNameData?.trim()?.toLowerCase();
    if (frequencyNameData !== frequencyName) {
      setFrequencyName(frequencyNameData);
    }
  }, [frequencyId, uniqueFrequencyOptionList, programId, frequencyOptionList]);
  useEffect(() => {
    scrollToTop();
  }, []);
  return (
    <Form
      layout="vertical"
      {...formProps}
      className="border px-4 bg-white pt-6 pb-4 mt-4 rounded-lg"
    >
      <Row gutter={16} className="mt-2">
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('common.program')}
            name="program"
            message={t('errorMessages.selectProgramMsg')}
            placeholder={t('placeholder.program')}
            onChange={() => onSelectProgram()}
            optionList={programOptionList}
          />
        </Col>
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('common.frequency')}
            name="frequency"
            placeholder={t('placeholder.frequency')}
            message={t('errorMessages.selectFrequencyMsg')}
            optionList={uniqueFrequencyOptionList?.map((item) => ({
              label: `${item?.frequency_name} `,
              value: item?.frequency_id,
            }))}
            disabled={!formProps.form?.getFieldValue('program')}
            onChange={() => onSelectFrequency()}
          />
        </Col>
        <Col xs={24} sm={24} lg={4}>
          <DropdownInput
            label={t('payoutHistory.workflow')}
            name="workflow"
            placeholder={t('payoutHistory.messages.workflow')}
            message={t('payoutHistory.messages.workflow')}
            optionList={workflowList?.map((item) => ({
              label: `${item?.workflow_name} `,
              value: item?.workflow_id,
            }))}
            disabled={!formProps.form?.getFieldValue('frequency')}
          />
        </Col>
        <Col xs={24} sm={24} lg={7}>
          <ComponentToRender
            frequencyName={frequencyName}
            setShowComponent={setShowComponent}
            showComponent={showComponent}
            disabled={!formProps.form?.getFieldValue('frequency')}
          />
        </Col>
        <Col xs={24} sm={24} lg={5}>
          <DropdownInput
            label={t('payoutHistory.payoutTableHeaders.status')}
            name="status"
            message={t('payoutHistory.messages.status')}
            placeholder={t('payoutHistory.messages.status')}
            optionList={statusDropdown}
            // disabled={!formProps.form?.getFieldValue('frequency')}
          />
        </Col>
      </Row>
      <Row gutter={16} className="justify-end gap-4 mt-2">
        <Button onClick={onReset}>{t('buttons.reset')}</Button>
        <Button htmlType="submit" type="primary">
          {t('buttons.apply')}
        </Button>
      </Row>
    </Form>
  );
};

export const UnificationHistoryView = () => {
  const t = useTranslate();
  const [frequencyName, setFrequencyName] = useState<string>();
  const { tableQueryResult, tableProps, searchFormProps, setFilters } =
    useTable<IPost, HttpError, FilterVariables>({
      resource: apiEndpoint.unificationRunStatus,
      dataProviderName: 'payoutProvider',
      onSearch: (params) => {
        const filters: CrudFilters = [];
        const { program, frequency, date, status, workflow } = params;
        filters.push(
          {
            field: 'iprogram_id',
            operator: 'contains',
            value: program,
          },
          {
            field: 'frequency_id',
            operator: 'contains',
            value: frequency,
          },
          {
            field: 'workflow_id',
            operator: 'contains',
            value: workflow,
          },
          {
            field: 'date',
            operator: 'contains',
            value: date ? getInitializationDate(frequencyName, date) : null,
          },
          {
            field: 'status',
            operator: 'contains',
            value: status,
          }
        );
        return filters;
      },
      syncWithLocation: false,
    });
  const unificationHistoryTableData = (
    tableQueryResult?.data?.data as BaseRecord
  )?.results;
  logger('Unification History Table Data', unificationHistoryTableData);
  if (tableQueryResult?.error?.response?.data) {
    logger(
      'Unification History Table Data Error::',
      tableQueryResult?.error?.response?.data
    );
  }
  return (
    <>
      <Card className="card-style flex items-center">
        <h4 className="card-title">{t('unificationList.title')}</h4>
      </Card>
      <Row gutter={[16, 16]} className="mt-4">
        <Col lg={24} xs={24}>
          <Filter
            setFilters={setFilters}
            formProps={searchFormProps}
            setFrequencyName={setFrequencyName}
            frequencyName={frequencyName}
          />
        </Col>
        <Col lg={24} xs={24}>
          <UnificationHistoryTable
            tableProps={tableProps}
            unificationHistoryTableData={unificationHistoryTableData}
          />
        </Col>
      </Row>
    </>
  );
};

export default UnificationHistoryView;
