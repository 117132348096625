import axiosInstance from 'providers/axiosInstance/axiosInstance';
import openNotificationWithIcon from 'core/utils/notification';
import constants from 'core/constants/Constants';

const directFileDownload = async (url: string, resetFields?: any) => {
  try {
    const response = await axiosInstance.get(url, {
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    let fileName = 'downloaded_file';
    const contentDisposition = response.headers['content-disposition'];

    if (contentDisposition) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDisposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }

    const fileType =
      response.headers['content-type'] || 'application/octet-stream';
    const blobData = new Blob([response.data], { type: fileType });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blobData);
    downloadLink.download = fileName;
    downloadLink.target = '_blank';
    downloadLink.click();
    URL.revokeObjectURL(downloadLink.href);

    if (response.status === 200) {
      openNotificationWithIcon(
        constants.success,
        'Success Message',
        'File Downloaded Successfully'
      );
      if (resetFields) {
        resetFields();
      }
    } else {
      openNotificationWithIcon(
        constants.error,
        'Error Message',
        'Something Went Wrong'
      );
    }
  } catch (error) {
    console.log('error', error);
    if (error.response) {
      const errorMessage =
        error.response.data.message || 'Something Went Wrong';
      openNotificationWithIcon('error', 'Error Message', errorMessage);
    } else {
      openNotificationWithIcon(
        'error',
        'Error Message',
        'Something Went Wrong'
      );
    }
    console.error('An error occurred:', error);
  }
};

export default directFileDownload;
