const statusDropdown: any[] = [
  {
    label: 'Unification Done',
    value: 'unification_done',
  },
  {
    label: 'Unification Cancelled',
    value: 'unification_cancelled',
  },
  {
    label: 'Data Not Available',
    value: 'data_not_available',
  },
];

export default statusDropdown;
