// package imports

// project imports
import "../treeNode.css"
import PopoverCard from "./PopoverCard";


const TreeNode = ({ node }) => {
    return (
        <div className="tree-node">
            {node.parent_source_name ? (
                <div className="node-content-parent">
                    <div>
                       <span className="node-content-children-label">{node.parent_source_name}</span>  <span className="node-content-children-output"  style={{ color :'darkgrey'}}> <span style={{ color: "#3d5ccb",fontSize:'16px'}}>{node?.output} </span></span>
                        {/* <PopoverCard node={node} /> */}
                    </div>
                    <div>
                        <span className="node-content-children-formula">{node.parent_formula}</span>  
                    </div>
                    {/* <div>
                        <span className="node-content-parent-label">Value</span> - {node?.output}
                    </div> */}
                </div>
            ) : (
                <div className="node-content-children">
                    {/* <div className={`node-content-${node.source_name}`}> */}
                    <div>
                    <span className="node-content-children-label">{node.source_name}</span>  <span className="node-content-children-output"  style={{ color :'darkgrey'}}>  <span style={{ color: "#3d5ccb" ,fontSize:'16px'}}>{node?.output} </span> </span>
                        {/* <PopoverCard node={node} /> */}
                    </div>
                    {node?.formula &&
                        <div>
                            <span className="node-content-children-formula">{node.formula}</span>  
                            </div>
                    }
                </div>
            )}

            {node.children?.length > 0 ? (
                <div className="children">
                    {node.children.map((child, index) => (
                        <TreeNode
                            key={index}
                            node={child}
                        />
                    ))}
                </div>
            ) : null}
        </div>
    )
}

export default TreeNode;