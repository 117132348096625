// Package Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
import Loading from 'components/loading/loading';
// Project Imports
const InputFileDownload = lazy(
  () => import('pages/inputFileDownload/inputFileDownload')
);
const InputFileDownloadRoute = (
  <Route path={routeName.inputFileDownload}>
    <Route
      index
      element={
        <PrivateRoute name={[PrivateRouteConst.INPUT_FILE_DOWNLOAD]}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <InputFileDownload />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default InputFileDownloadRoute;
