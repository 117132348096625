//Package Imports
import { Typography } from "antd";

//Project Imports
import "./a8breadcrumb.css";

const A8Breadcrumb = ({ title, icon, children }) => {
    const { Title } = Typography;
    return (
        <div className="a8-breadcrumb">
            {icon && (
                <div className="a8-breadcrumb-icon">
                    {icon}
                </div>
            )}
            <Title className="a8-breadcrumb-title" level={4}>{title}</Title>
            {children}
        </div>
    );
};

export default A8Breadcrumb;