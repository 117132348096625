import getAPiUrl from "./Methods/getApiUrl";
import getColumnElementValue from "./Methods/getColumnElementValue";
import formatCurrency from "./Methods/formatCurrency"
import formatDate from "./Methods/formatDate"
import expandableColumnTreeMap from "./Methods/expandableColumnTreeMap"
import { fileExtensions } from "./Constants/fileExtensions"
import { fileTypes } from "./Constants/fileTypes"
import { messages } from "./Constants/messages"
import { regularExpressions } from "./Constants/regularExpressions"
import { formBuilderFieldNames } from "./Constants/formBuilderFieldNames"
import { labels } from "./Constants/labels"
import { constant } from "./Constants/constant"

const A8Utils = {
    getColumnElementValue,
    getAPiUrl,
    formatCurrency,
    formatDate,
    expandableColumnTreeMap,
    constant,
    fileExtensions,
    fileTypes,
    messages,
    regularExpressions,
    formBuilderFieldNames,
    labels
}
export default A8Utils;