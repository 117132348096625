// Project Imports
import keycloak from 'keycloak/keyCloak';

const login = async () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const { to } = Object.fromEntries(urlSearchParams.entries());
  await keycloak.login({
    redirectUri: to ? `${window.location.origin}${to}` : undefined,
  });
  return {
    success: true,
  };
};

export default login;
