// eslint-disable-next-line import/named
// Package Imports
import { MetaQuery } from '@refinedev/core';

// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type OneParams = {
  apiUrl: string;
  resource: string;
  variables: Record<string, unknown>;
  meta?: MetaQuery;
};

const createMany = async ({ resource, variables, apiUrl }: OneParams) => {
  const url = `${apiUrl}/${resource}/`;
  const { data } = await axiosInstance.post(url, variables);
  return {
    data,
  };
};

export default createMany;
