// Project Imports
import getDateValue from './getDateValue';

export default function getYearDate(date) {
  const startMonthIndex = 0;
  const endMonthIndex = 12;

  const formattedStartDate = getDateValue(new Date(date, startMonthIndex, 1));
  const formattedEndDate = getDateValue(new Date(date, endMonthIndex, 0));

  return `${formattedStartDate} - ${formattedEndDate}`;
}
