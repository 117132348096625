const pdf = 'pdf';
const jpg = 'jpg';
const png = 'png';
const csv = 'csv';
const xls = 'xls';
const dmn = 'dmn';
const xlsx = 'xlsx';
const bpmn = 'bpmn';

const fileExtensions = {
    pdf, jpg, png, csv, xls, xlsx, dmn, bpmn
}

export { fileExtensions };