// Project Imports
import { useTokenContext } from 'contexts/resource/ResourceContext';

const usePermission = (): ((permissionName: string) => boolean) => {
  const { crudPermissions } = useTokenContext();

  return (permissionName: string): boolean =>
    crudPermissions?.flat()?.includes(permissionName);
};

export default usePermission;
