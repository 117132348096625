// package imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const PayoutHistoryView = lazy(
  () => import('pages/payoutHistory/payoutHistoryView')
);
const PayoutHistoryRoute = (
  <Route
    index
    path={routeName.payoutHistory}
    element={
      <PrivateRoute name={PrivateRouteConst.PAYOUT_HISTORY}>
        <Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          <PayoutHistoryView />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default PayoutHistoryRoute;
