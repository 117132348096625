const invoiceUploadFileFormatError = `Please Upload PDF,JPG,PNG format`;
const rejectButtonFileFormatError = `Please Upload CSV,XLS,XLSX,BPMN,DMN format`;
const enterOnlyNumbers = 'Please enter only numbers!'
const invoiceNumber = 'Please enter characters as alphabets, numbers, or symbols ("/", "-").';


const messages = {
    invoiceUploadFileFormatError,
    enterOnlyNumbers,
    rejectButtonFileFormatError,
    invoiceNumber
}

export { messages };