//Package Imports
import React, { useState } from 'react'
import { Button } from 'antd';

//Project Imports

const GridShowTimelineButton = (props) => {
    const { setShowTimeLineComponent, showTimeLineComponent } = props;

    const onShowTimeLineClick = () => {
        setShowTimeLineComponent(!showTimeLineComponent)
    }

    return (
        <>
            <Button className='grid-button' type="primary" size="large" onClick={() => onShowTimeLineClick()} >{showTimeLineComponent ? "Show Grid" : "Show Timeline"}</Button>
        </>
    )
}

export default GridShowTimelineButton