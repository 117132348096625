import {
  Avatar,
  Badge,
  Button,
  Dropdown,
  Grid,
  Menu,
  Select,
  Space,
} from 'antd';
import { MenuProps } from 'antd/lib';
import { useTokenContext } from 'contexts/resource/ResourceContext';
import React from 'react';
import { useTranslation } from 'react-i18next';
import imageFlag from 'core/common/imagePath';
import { useSetLocale, useGetLocale, useLogout } from '@refinedev/core';
import LanguageLabels from 'components/header/data';
import { BellOutlined, DownOutlined, UserOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import routeName from 'core/common/routeName';

const { useBreakpoint } = Grid;

const HeaderContainerView = ({
  saveLang,
  filterOption,
  saveOrg,
  saveRole,
  handleToggleNotifications,
  notificationsVisible,
}: any) => {
  const locale = useGetLocale();
  const { i18n } = useTranslation();
  const currentLocale = locale();
  const changeLanguage = useSetLocale();
  const navigate = useNavigate();
  const {
    orgId,
    setCurrentLang,
    organizationList,
    userRoleList,
    roleId,
    notificationMessages,
  } = useTokenContext();
  const screens = useBreakpoint();

  const { mutate: logout } = useLogout();
  const menuItemsLang: MenuProps['items'] = [...(i18n.languages || [])]
    .sort()
    .map((lang: string) => ({
      key: lang,
      onClick: async () => {
        await setCurrentLang(lang);
        await changeLanguage(lang);
        await saveLang(lang);
      },
      icon: (
        <span style={{ marginRight: 8 }}>
          <Avatar size={16} src={imageFlag(lang)} />
        </span>
      ),
      label: LanguageLabels[lang] || lang,
    }));

  const menu = (
    <Menu>
      <Menu.Item
        key="profile"
        onClick={() => {
          navigate(routeName.profile);
        }}
      >
        Profile
      </Menu.Item>
      <Menu.Item
        key="logout"
        onClick={() => {
          logout();
        }}
      >
        Logout
      </Menu.Item>
    </Menu>
  );

  const handleOrgChange = (e: string) => {
    saveOrg(e);
  };

  const handleRoleChange = (e) => {
    saveRole(e);
  };
  function getNotificationCount(notificationmsg) {
    return notificationmsg?.filter((msg) => !msg?.read)?.length; // return non read messages
  }
  return (
    <Space>
      {organizationList?.length > 1 ? (
        <Select
          className="org-select"
          style={{ width: 300 }}
          value={organizationList?.length > 0 ? orgId : undefined}
          placeholder="Select Organisation"
          onChange={(e) => handleOrgChange(e)}
          showSearch
          options={organizationList}
          optionFilterProp="children"
          filterOption={filterOption}
        />
      ) : null}
      {userRoleList?.length > 1 ? (
        <Select
          className="org-select"
          style={{ width: 300 }}
          value={userRoleList?.length > 0 ? roleId : null}
          placeholder="Select Role"
          onChange={(e) => handleRoleChange(e)}
          showSearch
          options={userRoleList}
          optionFilterProp="children"
          filterOption={filterOption}
        />
      ) : null}
      <Dropdown
        className="multilingual"
        menu={{
          items: menuItemsLang,
          selectedKeys: currentLocale ? [currentLocale] : [],
        }}
      >
        <a
          style={{ color: 'inherit' }}
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          <Space>
            <Avatar size={16} src={imageFlag(currentLocale)} />
            <div
              style={{
                display: screens.lg ? 'block' : 'none',
              }}
            >
              {LanguageLabels[currentLocale]}
              <DownOutlined
                style={{
                  fontSize: '12px',
                  marginLeft: '6px',
                }}
              />
            </div>
          </Space>
        </a>
      </Dropdown>

      <div id="notifications">
        <Badge
          count={
            !notificationsVisible &&
            notificationMessages &&
            notificationMessages?.length > 0
              ? getNotificationCount(notificationMessages)
              : 0
          }
          className="z-10"
        >
          <BellOutlined
            onClick={handleToggleNotifications}
            style={{ fontSize: '24px', color: 'black', cursor: 'pointer' }}
          />
        </Badge>
      </div>

      <Dropdown overlay={menu} placement="bottomRight">
        <Space size="middle">
          <Button
            style={{
              color: 'primary',
              fontWeight: 'bold',
              borderRadius: '50%',
            }}
            icon={<UserOutlined />}
          />
        </Space>
      </Dropdown>
    </Space>
  );
};

export default HeaderContainerView;
