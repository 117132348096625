/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { createContext, useContext, useEffect, useState } from 'react';
import type ResourceItem from 'interfaces/resource';
import { Permission, Token, TokenContextType } from 'contexts/resource/type';
import fetchPermissions from 'core/utils/fetchPermissions';
import { useSetLocale } from '@refinedev/core';
import fetchOrganization from 'core/utils/fetchOrg';
// eslint-disable-next-line import/no-extraneous-dependencies
import io, { Socket } from 'socket.io-client';
import logger from 'core/utils/logger';
import fetchNotificatons from 'core/utils/fetchNotifications';
import fetchRoles from 'core/utils/fetchRoles';
import axiosInstance from 'providers/axiosInstance/axiosInstance';

const OrgIDKey = 'orgId';
const roleIdKey = 'roleId';

const TokenContext = createContext<TokenContextType>({
  token: undefined,
  setToken: () => {},
  crudPermissions: [],
  setCrudPermission: () => {},
  modulePermission: [],
  setModulePermission: () => {},
  userDetails: {},
  setUserDetails: () => {},
  filteredResources: [],
  setFilteredResources: () => {},
  loading: false,
  setLoading: () => {},
  orgId: '',
  setOrgId: () => {},
  roleId: '',
  setRoleId: () => {},
  userRoleList: [],
  organizationList: [],
  setOrganizationDropdownList: () => {},
  currentLang: '',
  setCurrentLang: () => {},
  setRoles: () => {},
  roles: [],
  socketInstance: undefined,
  setSocketInstance: () => {},
  setNotificationMessages: () => {},
  notificationMessages: [],
  setRoleName: () => {},
  roleName: '',
});

export function useTokenContext() {
  return useContext(TokenContext);
}

const TokenProvider = ({ children }: { children: React.ReactNode }) => {
  const [token, setToken] = useState<Token>(undefined);
  const [crudPermissions, setCrudPermission] = useState<Permission>([]);
  const [modulePermission, setModulePermission] = useState<Permission>([]);
  const [filteredResources, setFilteredResources] = useState<ResourceItem[]>(
    []
  );
  const [loading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [roles, setRoles] = useState([]);
  const [roleName, setRoleName] = useState();
  const [orgId, setOrgIdState] = useState<string | number>(() => {
    const storedOrgId = localStorage.getItem(OrgIDKey);
    return storedOrgId || '';
  });
  const [roleId, setRoleIdState] = useState<string>(() => {
    const storedRoleId = localStorage.getItem(roleIdKey);
    return storedRoleId || '';
  });
  const [organizationList, setOrganizationList] = useState<any[]>([]);
  const [userRoleList, setUserRoleList] = useState<any[]>([]);
  const [currentLang, setCurrentLang] = useState('en');
  const changeLanguage = useSetLocale();
  const [socketInstance, setSocketInstance] = useState<Socket | undefined>(
    undefined
  );

  const [notificationMessages, setNotificationMessages] = useState<any>(() => {
    const notificationList = localStorage.getItem('notifications');
    return (notificationList && JSON.parse(notificationList)) || [];
  });

  // Function to set orgId in both state and localStorage
  const setOrgId = (newOrgId: string) => {
    setOrgIdState(newOrgId);
  };

  // Function to set roleId in both state and localStorage
  const setRoleId = (newRoleId: any) => {
    setRoleIdState(newRoleId);
  };

  const connect = (id, orgid) => {
    const userId = id;
    if (socketInstance) {
      logger(
        'Socket instance already exists. Not establishing a new connection.'
      );
      return;
    }

    if (userId && orgid) {
      const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
        query: { userId, orgid },
      });
      newSocket.on('connect', () => {
        logger('Connected to Socket.IO server with userId:', userId);
      });

      newSocket.emit('joinRoom', { roomId: `${userId}_${orgid}` });

      // Set the new socket instance
      setSocketInstance(newSocket);
    } else {
      logger(
        'userId or orgId is undefined. Socket connection not established.'
      );
    }
  };

  useEffect(() => {
    if (socketInstance && userDetails) {
      fetchNotificatons(setNotificationMessages, userDetails, socketInstance);
    }
  }, [socketInstance]);

  console.log(socketInstance, 'socketInstance');

  const fetchData = async () => {
    setLoading(true);
    axiosInstance.defaults.headers.common = {
      Authorization: `Bearer ${token}`,
    };
    await fetchOrganization(setOrganizationList);
    await fetchRoles(setUserRoleList);
    await fetchPermissions(
      setFilteredResources,
      setCrudPermission,
      setModulePermission,
      setUserDetails,
      changeLanguage,
      setCurrentLang,
      connect,
      currentLang,
      token,
      setOrgId,
      setRoleId
    );
    setLoading(false);
  };

  useEffect(() => {
    if (token) {
      fetchData();
    }
  }, [token]);

  console.log(userDetails, roleId, 'userDetails');

  return (
    <TokenContext.Provider
      value={{
        token,
        setToken,
        crudPermissions,
        setCrudPermission,
        modulePermission,
        setModulePermission,
        filteredResources,
        setFilteredResources,
        userDetails,
        setUserDetails,
        loading,
        setLoading,
        orgId,
        setOrgId,
        setRoleId,
        roleId,
        userRoleList,
        organizationList,
        setOrganizationDropdownList: setOrganizationList,
        currentLang,
        setCurrentLang,
        roles,
        setRoles,
        socketInstance,
        setSocketInstance,
        notificationMessages,
        setNotificationMessages,
        roleName,
        setRoleName,
      }}
    >
      {children}
    </TokenContext.Provider>
  );
};

export default TokenProvider;
