//Package Imports
import React, { useEffect, useState } from 'react'
import { ArrowLeftOutlined, InboxOutlined } from '@ant-design/icons';
import { Form, Select, Input, Upload, Button, Drawer } from 'antd';

//Project Imports
import A8Utils from '../../../utils/A8Utils';
import A8Breadcrumb from '../../../Augment8/A8BreadCrumb/A8BreadCrumb';

const GridAdjustmentDrawer = (props) => {

    const { visible, onOk, onCancel, data, addAdjustmentApiUrlParamsList, addAdjustmentUrl, submissionData, addAdjustmentApiUrlPayloadParamsList, reasonsFieldApiUrl, reasonsFieldApiUrlParamsList, reasonsFieldApiResponseDataKey } = props;

    const { TextArea } = Input;
    const { Dragger } = Upload;
    const FormItem = Form.Item;
    const [form] = Form.useForm();

    const [fileList, setFileList] = useState([])
    const [reasonOptionList, setReasonOptionList] = useState([])

    const backArrow = <ArrowLeftOutlined className='back-arrow' onClick={() => onCancel()} />

    useEffect(() => {
        getReasonApiCallHandler()
    }, []);

    const reasonFormItemLayout = {
        wrapperCol: { span: 23 },
    };

    const adjustmentFormItemLayout = {
        wrapperCol: { span: 8 },
    };

    const uploadFormItemLayout = {
        wrapperCol: { span: 23 },
    };

    const remarkFormItemLayout = {
        wrapperCol: { span: 23 },
    };

    const filterOption = (input, option) => {
        (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
    }

    const onSearch = (value) => {
        console.log('search:', value);
    };

    const onChange = (value) => {
        console.log(`selected ${value}`);
    };

    const resetForm = () => {
        form.resetFields()
    }

    const validateNumber = (_, value) => {
        if (!value || /^[0-9]+$/.test(value)) {
            return Promise.resolve();
        }
        return Promise.reject(new Error('Please enter only numbers!'));
    };

    const addAdjustmentApiCall = (url, data) => {
        const token = localStorage.getItem('token');
        const tokenType = localStorage.getItem('tokenType')
        const modifiedConfig = {
            headers: {
              'Content-Type': 'application/json',
              ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
            },
          };
       
        fetch(url, {
            headers: modifiedConfig?.headers,
            method: 'POST',
            body: data
        })
        
            .then(r => r.json())
            .then(data => {
                console.log("addAdjustmentApiCallHandler::::responseData", data)
                resetForm()
                onCancel()
            })
            .catch(error => console.log(error))
    }

    const addAdjustmentApiCallHandler = (values) => {
        let formData = new FormData()
        const selectedElement = data;

        const payloadParameterGrid = addAdjustmentApiUrlPayloadParamsList;
        console.log("payloadParameterGrid::::", payloadParameterGrid)

        let adjustmentDetailsObject = {}
        payloadParameterGrid?.forEach((item) => {
            if (item.addAdjustmentApiUrlTarget === "formData") {
                if (item.addAdjustmentApiUrlPayloadParameter !== "file") {
                    adjustmentDetailsObject[item.addAdjustmentApiUrlPayloadParameter] = values[item.addAdjustmentApiUrlPayloadTargetKey]
                }
            }
            if (item.addAdjustmentApiUrlTarget === "rowData") {
                adjustmentDetailsObject[item.addAdjustmentApiUrlPayloadParameter] = selectedElement[item.addAdjustmentApiUrlPayloadTargetKey]
            }
        })

        let adjustmentDetails = new Blob(
            [JSON.stringify(adjustmentDetailsObject)],
            {
                type: "application/json",
            }
        )
        formData.append("manualAdjustmentDetail", adjustmentDetails)

        if (typeof values?.referenceFile == "object") {
            formData.append("file", values?.referenceFile)
        }

        let apiUrlParams = {}
        addAdjustmentApiUrlParamsList?.forEach((item) => {
            apiUrlParams[item.addAdjustmentApiUrlParameter] = A8Utils.getColumnElementValue(submissionData, item.addAdjustmentApiUrlTargetKey)
        })

        let url = A8Utils.getAPiUrl(addAdjustmentUrl, apiUrlParams)

        addAdjustmentApiCall(url, formData)
    }

    const handleReasonOptionList = (data) => {
        const list = A8Utils.getColumnElementValue(data, reasonsFieldApiResponseDataKey)
        const modifiedList = list.map(item => ({
            ...item,
            label: item.reason,
            value: item.reason
        }));
        setReasonOptionList(modifiedList)
    }

    const getReasonApiCallHandler = () => {
        let apiUrlParams = {}
        reasonsFieldApiUrlParamsList?.forEach((item) => {
            if (item.reasonsFieldApiUrlParameter !== "") {
                apiUrlParams[item.reasonsFieldApiUrlParameter] = A8Utils.getColumnElementValue(submissionData, item.reasonsFieldApiUrlTargetKey)
            }
        })
        const apiUrlParamsHasUndefined = Object.values(apiUrlParams).some(value => value === undefined);

        let url = reasonsFieldApiUrl;
        if (!apiUrlParamsHasUndefined) {
            url = A8Utils.getAPiUrl(reasonsFieldApiUrl, apiUrlParams)
        }

        const token = localStorage.getItem('token');
        const tokenType = localStorage.getItem('tokenType')
        const modifiedConfig = {
            headers: {
              'Content-Type': 'application/json',
              ...(token && tokenType ? { Authorization: `${tokenType} ${token}` } : {}),
            },
          };
       
        fetch(url, {
            headers: modifiedConfig?.headers,
            
        })
            .then((res) => res.json())
            .then((json) => {
                handleReasonOptionList(json)
            })
            .catch((error) => {
            })

    }

    const normFile = (e) => {
        return e.fileList[0];
    };

    const uploadComponentProps = {
        name: 'referenceFile',
        multiple: false,
        maxCount: 1,
        showUploadList: true,
        onRemove: (file) => {
            setFileList(prevFileList => {
                const index = prevFileList.indexOf(file);
                const newFileList = [...prevFileList];
                newFileList.splice(index, 1);
                return newFileList;
            });
            return true;
        },
        beforeUpload: (file) => {
            setFileList([...fileList, file]);
            return false;
        },
    };

    const handleSubmit = (e) => {
        form.validateFields()
            .then((values) => {
                console.log("FormValues:::", values)
                addAdjustmentApiCallHandler(values)
            })
            .catch((errorInfo) => { });
    };

    return (
        <Drawer
            width={720}
            onClose={() => {
                onCancel()
                resetForm()
            }}
            open={visible}
            styles={{
                body: {
                    paddingTop: 10,
                },
            }}
        >
            <A8Breadcrumb title={A8Utils.labels.adjustment} icon={backArrow} />
            <Form form={form} className="form-wrapper"  >
                <div className='field-floating-label'>
                    <label >{A8Utils.labels.adjustmentAmount} <span className='asterisk'>*</span></label>
                    <FormItem
                        {...adjustmentFormItemLayout}
                        name="adjustmentAmount"
                        rules={[{ required: true, message: A8Utils.labels.adjustmentAmountValidation }, { validator: validateNumber }]}
                    >
                        <Input size="large" />
                    </FormItem>
                </div>
                <div className='field-floating-label'>
                    <label >{A8Utils.labels.reason} <span className='asterisk'>*</span></label>
                    <FormItem
                        {...reasonFormItemLayout}
                        name="reason"
                        rules={[{ required: true, message: A8Utils.labels.reasonValidation }]}
                    >
                        <Select
                            className=''
                            size="large"
                            showSearch
                            placeholder={A8Utils.labels.reasonPlaceholder}
                            optionFilterProp="children"
                            onChange={onChange}
                            onSearch={onSearch}
                            filterOption={filterOption}
                            options={reasonOptionList}
                        />
                    </FormItem>
                </div>
                <div className='field-floating-label'>
                    <label >{A8Utils.labels.referenceFile} <span className='asterisk'>*</span></label>
                    <FormItem
                        {...uploadFormItemLayout}
                        name="referenceFile"
                        rules={[{ required: true, message: A8Utils.labels.referenceFileValidation }]}
                        getValueFromEvent={normFile}
                    >
                        <Dragger {...uploadComponentProps}>
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">{A8Utils.labels.uploadComponentTitle}</p>
                            <p className="ant-upload-hint">{A8Utils.labels.uploadComponentSubTitle}</p>
                        </Dragger>
                    </FormItem>
                </div>
                <div className='field-floating-label'>
                    <label >{A8Utils.labels.remark} <span className='asterisk'>*</span></label>
                    <FormItem
                        {...remarkFormItemLayout}
                        name="remark"
                        rules={[{ required: true, message: A8Utils.labels.remarkValidation }]}
                    >
                        <TextArea
                            size="large"
                            rows={4}
                            placeholder={A8Utils.labels.remarkPlaceholder}
                            maxLength={100} />
                    </FormItem>
                </div>
                <div className='drawer-buttons-wrapper'>
                    <Button
                        className='btn-negative'
                        size="large"
                        onClick={() => {
                            onCancel()
                            resetForm()
                        }}
                    >
                        {A8Utils.labels.cancel}
                    </Button>
                    <Button
                        className='btn-positive'
                        size="large"
                        onClick={() => handleSubmit()}
                        type="primary"
                    >
                        {A8Utils.labels.submit}
                    </Button>
                </div>
            </Form>
        </Drawer>
    )
}

export default GridAdjustmentDrawer