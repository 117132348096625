// Package Imports
import { DownloadOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import PrivateRouteConst from 'core/common/privateRouteConstant';

const InputFileDownloadResource: ResourceItem = {
  name: resourceName.inputFileDownload,
  identifier: PrivateRouteConst.INPUT_FILE_DOWNLOAD,
  list: `${resourceName.inputFileDownload}`,
  meta: {
    label: 'Download',
    icon: <DownloadOutlined />,
    parent: resourceName.fileManagement,
  },
};

export default InputFileDownloadResource;
