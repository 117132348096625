// Package Imports
import { ScheduleOutlined } from '@ant-design/icons';

// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const MyTaskResource: ResourceItem = {
  name: resourceName.myTask,
  identifier: PrivateRouteConst.MY_TASK,
  show: `${resourceName.myTask}/show/:id`,
  options: { label: 'My tasks', icon: <ScheduleOutlined /> },
};

export default MyTaskResource;
