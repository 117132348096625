// Package Imports
import { DatabaseOutlined } from '@ant-design/icons';
import PrivateRouteConst from 'core/common/privateRouteConstant';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const UnificationHistoryResource: ResourceItem = {
  name: resourceName.unificationHistory,
  identifier: PrivateRouteConst.UNIFICATION_HISTORY,
  list: `${resourceName.unificationHistory}`,
  show: `${resourceName.unificationHistory}/show/:id`,
  meta: {
    label: 'Unification History',
    icon: <DatabaseOutlined />,
    parent: resourceName.payout,
  },
};

export default UnificationHistoryResource;
