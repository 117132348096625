// package imports
import { DownloadOutlined } from '@ant-design/icons';

const TreeNode = ({ node, onFinishHandler }) => (
  <div className="tree-node">
    {node.parent_source_name ? (
      <div className="node-content-parent">
        {node.parent_source_name}
        <DownloadOutlined
          className="cursor-pointer"
          onClick={() => onFinishHandler(node)}
        />
      </div>
    ) : (
      <div className={`node-content-${node.source_type}`}>
        {node.source_name}
        <DownloadOutlined
          className="cursor-pointer"
          onClick={() => onFinishHandler(node)}
        />
      </div>
    )}

    {node.children?.length > 0 ? (
      <div className="children">
        {node.children.map((child) => (
          <TreeNode
            key={child.unique_id}
            node={child}
            onFinishHandler={onFinishHandler}
          />
        ))}
      </div>
    ) : null}
  </div>
);

export default TreeNode;
