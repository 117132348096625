// Package Imports
import { BaseKey, MetaQuery } from '@refinedev/core';

// Project Imports
import getList from 'providers/apiProviders/getList';
import getMany from 'providers/apiProviders/getMany';
import getOne from 'providers/apiProviders/getOne';
import create from 'providers/apiProviders/create';
import update from 'providers/apiProviders/update';
import deleteOne from 'providers/apiProviders/deleteOne';
import createMany from 'providers/apiProviders/createMany';

export const customDataProvider = (apiUrl: string): any => ({
  getList: (options: {
    apiUrl: string;
    resource: string;
    pagination: { current?: number; pageSize?: number; mode?: string };
    sort?: { field?: string | number; order?: string | number }[];
    filters?: {
      field?: string | number;
      operator?: string | number;
      value: string | number;
    }[];
    meta?: MetaQuery;
  }) => getList({ apiUrl, ...options }),
  getMany: (options: { resource: string; ids?: BaseKey[]; meta: MetaQuery }) =>
    getMany({ apiUrl, ...options }),
  create: (options: { resource: string; variables: Record<string, unknown> }) =>
    create({ apiUrl, ...options }),
  update: (options: {
    resource: string;
    id: string | number;
    variables: Record<string, unknown>;
  }) => update({ apiUrl, ...options }),
  deleteOne: (options: { resource: string; id?: number }) =>
    deleteOne({ apiUrl, ...options }),
  createMany: (options: {
    resource: string;
    variables: Record<string, unknown>;
    meta?: MetaQuery;
  }) => createMany({ apiUrl, ...options }),
  getOne: (options: {
    resource: string;
    id: string | number;
    meta?: MetaQuery;
  }) => getOne({ apiUrl, ...options }),
});

export default customDataProvider;
