// Package Imports

// Project Imports
import { monthlyList } from 'pages/payoutInitialization/dropdownlist';
import getDateValue from './getDateValue';

export default function getDateInterval(date) {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  let formattedStartDate;
  let formattedEndDate;

  if (date?.includes('-')) {
    const [startMonth, endMonth] = date.split('-');
    const startMonthIndex = monthlyList.findIndex(
      (month) => month.value === startMonth
    );
    const endMonthIndex = monthlyList.findIndex(
      (month) => month.value === endMonth
    );
    formattedStartDate = getDateValue(new Date(year, startMonthIndex, 1));
    formattedEndDate = getDateValue(new Date(year, endMonthIndex + 1, 0));
  } else {
    const monthIndex = monthlyList.findIndex((month) => month.value === date);
    formattedStartDate = getDateValue(new Date(year, monthIndex, 1));
    formattedEndDate = getDateValue(new Date(year, monthIndex + 1, 0));
  }
  return `${formattedStartDate} - ${formattedEndDate}`;
}
