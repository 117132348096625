// Project imports
import logger from 'core/utils/logger';
import getDateValue from 'core/utils/getDateValue';
import getWeeklyDateValue from 'core/utils/getWeeklyDateValue';
import getDateInterval from 'core/utils/getDateInterval';
import getYearDate from 'core/utils/getYearDate';

export default function getInitializationDate(frequency, date) {
  logger('frequency>>>>>>>>>>', frequency, date);

  switch (frequency) {
    case 'daily':
      return getDateValue(date);

    case 'weekly':
      return getWeeklyDateValue(date);

    case 'monthly':
      return getDateInterval(date);

    case 'quarterly':
    case 'half yearly':
      return getDateInterval(date);

    case 'yearly':
      return getYearDate(date);

    default:
      return null;
  }
}
