// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
// import PrivateRoute from 'core/utils/PrivateRoute';
// import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';
// Lazy-loaded Component

const CalculationTable = lazy(
  () => import('pages/accounting/calculationTable')
);

const AccountingRoute = (
  <Route path={routeName.accounting}>
    <Route
      index
      element={
        // <PrivateRoute name={PrivateRouteConst.ACCOUNTING}>
        <Suspense fallback={<Loading />}>
          <CalculationTable />
        </Suspense>
        // </PrivateRoute>
      }
    />
  </Route>
);

export default AccountingRoute;
