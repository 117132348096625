// Package Imports
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const PayoutResource: ResourceItem = {
  name: resourceName.payout,
  identifier: ModuleIdentifierConst.PAYOUT,
  meta: {
    label: 'Payout',
  },
};

export default PayoutResource;
