const cardDummyData = {
    "dsaCode": 12345,
    "product": {
        "name": "CVC"
    },
    "state": "Maharashtra",
    "totalCaseCount": {
        "product": {
            "count": 10
        }
    },
    "valueOfContracts": 56789,
    "calculatedPayout": 98765,
    "totalAdjustment": 99,
    "finalPayout": 78678,
    "dsaName": "DEEPESH AGENCY",
    "month": "March",
    "year": "2024"
}

export default cardDummyData;