// Package Imports
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import Login from 'pages/login';

const LoginRoute = <Route path={routeName.login} element={<Login />} />;

export default LoginRoute;
