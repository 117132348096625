// Package Imports
import { PayCircleOutlined } from '@ant-design/icons';
import PrivateRouteConst from 'core/common/privateRouteConstant';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const PayoutInitializationResource: ResourceItem = {
  name: resourceName.payoutInitialization,
  identifier: PrivateRouteConst.START_PAYOUT,
  list: `${resourceName.payoutInitialization}`,
  meta: {
    label: 'Payout Initialization',
    icon: <PayCircleOutlined />,
    parent: resourceName.payout,
  },
};

export default PayoutInitializationResource;
