const dateFormat = 'DD/MM/YYYY';
const ddmmmmyyyy = 'DD MMMM YYYY';
const yyyymmdd = 'YYYY-MM-DD';
const XlsType = 'application/vnd.ms-excel';
const XlsWpsType = 'application/wps-office.xls';
const XlsxType = 'application/wps-office.xlsx';
const OtherXlsx =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
const CsvType = 'text/csv';
const JsonType = 'application/json';
const JsType = 'text/javascript';
const pythonDjangoType = 'text/x-python';
const DmnType = 'dmn';
const dateFormatWithHyphen = 'YYYY-MM-DD';
const success = 'success';
const error = 'error';
const info = 'info';
const warning = 'warning';
const DMYFormatWithHyphen = 'DD-MM-YYYY';

const constants = {
  dateFormat,
  ddmmmmyyyy,
  yyyymmdd,
  XlsType,
  XlsWpsType,
  XlsxType,
  OtherXlsx,
  CsvType,
  JsonType,
  JsType,
  pythonDjangoType,
  DmnType,
  dateFormatWithHyphen,
  success,
  error,
  info,
  warning,
  DMYFormatWithHyphen,
};

export default constants;
