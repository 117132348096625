// Package Imports
import React, { useEffect } from 'react';
import { useLogin } from '@refinedev/core';
import { Layout, Space } from 'antd';

const Login: React.FC = () => {
  const { mutate: login } = useLogin();

  useEffect(() => {
    localStorage.clear();
    login({});
  }, []);

  return (
    <Layout
      style={{
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Space direction="vertical" align="center">
        {/* <Button
          style={{ width: '240px' }}
          type="primary"
          size="middle"
          onClick={() => {
            login({});
          }}
        >
          Sign in
        </Button>
        <Typography.Text type="secondary">
          Powered by
          <img
            style={{ padding: '0 5px' }}
            alt="Keycloak"
            src="https://refine.ams3.cdn.digitaloceanspaces.com/superplate-auth-icons%2Fkeycloak.svg"
          />
          Keycloak
        </Typography.Text> */}
      </Space>
    </Layout>
  );
};

export default Login;
