// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import Loading from 'components/loading/loading';

const MyTaskTable = lazy(() => import('pages/myTask/myTaskVIew'));
const TaskShow = lazy(() => import('pages/myTask/myTaskShow'));

const MyTaskRoute = (
  <Route path={routeName.myTask}>
    <Route
      index
      element={
        <PrivateRoute name={PrivateRouteConst.MY_TASK}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <MyTaskTable />
          </Suspense>
        </PrivateRoute>
      }
    />

    <Route
      path="show/:id"
      element={
        <PrivateRoute name={PrivateRouteConst.MY_TASK}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <TaskShow />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default MyTaskRoute;
