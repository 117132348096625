// Project Imports
import openNotificationWithIcon from 'core/utils/notification';

function updateOrAddNotificationMsg(notificationArray, newNotificationMsg) {
  // Find the index of the object that has the matching key and value
  const index = notificationArray?.findIndex(
    (obj) => obj?.uniqueId === newNotificationMsg?.uniqueId
  );

  if (index !== -1) {
    // If newNotificationMsg is found in notificationArray, update the process with new newNotificationMsg
    // eslint-disable-next-line no-param-reassign
    notificationArray[index] = newNotificationMsg;
  } else {
    // If newNotificationMsg is not found, add the newNotificationMsg to the notificationArray
    notificationArray?.push(newNotificationMsg);
  }

  return notificationArray;
}

const updateNotifications = (
  newMessage,
  userId,
  id,
  setNotificationMessages
) => {
  const notificationList = localStorage.getItem('notifications');
  const notificationLocalstorage =
    JSON.parse(notificationList)?.length > 0
      ? JSON.parse(notificationList)
      : [];
  const updatedMessages = updateOrAddNotificationMsg(
    notificationLocalstorage,
    newMessage
  );
  if (updatedMessages?.length > notificationLocalstorage?.length) {
    // show notification if new message added
    openNotificationWithIcon(
      newMessage.status,
      newMessage?.title,
      newMessage?.message
    );
  }
  if (userId === id) {
    localStorage.setItem('notifications', JSON.stringify(updatedMessages));
    setNotificationMessages(updatedMessages);
  }
};

const fetchNotificatons = async (
  setNotificationMessages,
  userDetails,
  socketInstance
): Promise<void> => {
  try {
    socketInstance.on('input_data_file_upload', (data) => {
      console.log('input_data_file_upload ', data);
      const newMessage = {
        title: data?.event_name,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
        progress: data?.completion_percentage,
        uniqueId: data?.workflow_status_id,
        sourceId: data?.source_id,
        read: false,
        extraKeys: data?.extra_keys,
      };
      updateNotifications(
        newMessage,
        userDetails?.unique_id,
        data?.userId,
        setNotificationMessages
      );
    });
    socketInstance.on('master_data_file_upload', (data) => {
      console.log('master_data_file_upload ', data);
      const newMessage = {
        title: data?.event_name,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
        progress: data?.completion_percentage,
        uniqueId: data?.workflow_status_id,
        sourceId: data?.source_id,
        read: false,
        extraKeys: data?.extra_keys,
      };
      updateNotifications(
        newMessage,
        userDetails?.unique_id,
        data?.userId,
        setNotificationMessages
      );
    });
    socketInstance.on('initiate_unification', (data) => {
      console.log('initiate_unification ', data);
      const newMessage = {
        title: data?.event_name,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
        progress: data?.completion_percentage,
        uniqueId: data?.workflow_status_id,
        sourceId: data?.source_id,
        read: false,
        extraKeys: data?.extra_keys,
      };
      updateNotifications(
        newMessage,
        userDetails?.unique_id,
        data?.userId,
        setNotificationMessages
      );
    });
    socketInstance.on('start_payout', (data) => {
      console.log('start_payout ', data);
      const newMessage = {
        title: data?.event_name,
        timestamp: data?.date_time,
        message: data.message,
        status: data?.status,
        userId: data?.userId,
        progress: data?.completion_percentage,
        uniqueId: data?.workflow_status_id,
        read: false,
        extraKeys: data?.extra_keys,
      };
      updateNotifications(
        newMessage,
        userDetails?.unique_id,
        data?.userId,
        setNotificationMessages
      );
    });
  } catch (error) {
    console.log('error socket ', error);
  }
};

export default fetchNotificatons;
