// Package Imports
import { ProjectOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const FileManagement: ResourceItem = {
  name: resourceName.fileManagement,
  identifier: ModuleIdentifierConst.FILE_MANAGEMENT,
  options: {
    label: 'File Management',
    icon: <ProjectOutlined />,
  },
};

export default FileManagement;
