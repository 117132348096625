const getQuerryParamAddedUrl = (apiUrl, querryParams) => {
    let updatedUrl = apiUrl;

    for (const key in querryParams) {
        updatedUrl = `${updatedUrl}${key}=${querryParams[key]}&`
    }
    updatedUrl = updatedUrl.replace(/&$/, '');

    return updatedUrl;
}

const getAPiUrl = (url, urlParams, querryParams) => {
    try {
        let apiUrl = `${url}`

        for (const key in urlParams) {
            apiUrl = apiUrl.replace(new RegExp(`:${key}\\b`, 'gm'), urlParams[key] || `:${key}`)
        }

        if (querryParams) {
            apiUrl = `${apiUrl}?`
            let updatedUrlWithPaginationParams = getQuerryParamAddedUrl(apiUrl, querryParams)
            return updatedUrlWithPaginationParams;
        } else {
            return apiUrl;
        }
    } catch (error) {
        console.log("error>>>>", error)
    }
}

export default getAPiUrl;