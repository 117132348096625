// Package Imports
import React from 'react';
import { Form, Select } from 'antd';

// Project Imports
import { DropdownInputProps } from 'components/dropdown/type';

const DropdownInput = ({
  label,
  name,
  required,
  optionList,
  message,
  style,
  values,
  disabled,
  onChange,
  placeholder,
  onSearch,
  showSearch,
  mode,
  size = 'middle',
}: DropdownInputProps) =>
  name !== undefined ? (
    <div
      className={
        disabled
          ? 'floating-disabled relative custom-dropdown'
          : 'relative custom-dropdown'
      }
    >
      <label className="floating-label">
        {label} {required ? <span className="a8-error">*</span> : null}
      </label>
      <Form.Item
        name={[name]}
        rules={[{ required, message }]}
        hasFeedback
        style={style}
      >
        <Select
          onChange={onChange}
          placeholder={placeholder}
          options={optionList}
          showSearch={showSearch}
          onSearch={onSearch}
          filterOption={false}
          mode={mode}
          disabled={disabled}
          size={size}
          // className={size === 'small' ? 'py-sm' : 'py-md'}
        />
      </Form.Item>
    </div>
  ) : (
    <div className="relative custom-dropdown">
      <label className="floating-label">
        {label} {required ? <span className="a8-error">*</span> : null}
      </label>
      <Select
        value={values}
        disabled={disabled}
        style={{ display: 'flex', justifyContent: 'center' }}
        onChange={onChange}
        options={optionList}
        placeholder="Select"
        showSearch={showSearch}
        onSearch={onSearch}
        size={size}
        // className={size === 'small' ? 'py-sm' : 'py-md'}
      />
    </div>
  );

export default DropdownInput;
