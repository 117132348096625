// Package Imports
import React from 'react';
import { Spin } from 'antd';

type LoadingType = {
  componentLoading?: boolean;
};
const Loading = ({ componentLoading }: LoadingType) => (
  <div
    className={`flex items-center justify-center ${
      componentLoading ? 'h-full' : 'h-screen'
    } `}
  >
    <Spin size="large" />
  </div>
);

export default Loading;
