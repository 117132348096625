// Package Imports

// Project Imports
import { FileAddOutlined } from '@ant-design/icons';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const MasterFileUploadResource: ResourceItem = {
  name: resourceName.masterFileUpload,
  identifier: PrivateRouteConst.MASTER_FILE_UPLOAD,
  list: `${resourceName.masterFileUpload}`,
  meta: {
    label: 'Upload',
    icon: <FileAddOutlined />,
    parent: resourceName.maintainence,
  },
};

export default MasterFileUploadResource;
