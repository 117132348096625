export default function getFormattedDate(dateStr) {
  const date = new Date(dateStr);

  // Extract components
  const day = String(date.getDate()).padStart(2, '0');
  const month = date.toLocaleString('default', { month: 'short' });
  const year = date.getFullYear();

  let hours = date.getHours();
  const minutes = date.getMinutes();
  const ampm = hours >= 12 ? 'PM' : 'AM';

  // Convert to 12-hour format
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'

  // Format minutes to have leading zero if needed
  const minutesFormatted = minutes < 10 ? `0${minutes}` : minutes;

  // Create formatted date string
  const formattedDate = `${day} ${month} ${year} ${String(hours).padStart(
    2,
    '0'
  )}:${minutesFormatted} ${ampm}`;

  return formattedDate;
}
