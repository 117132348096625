// Package Imports
import React, { Component } from "react";
import { Formio, ReactComponent } from "react-formio";
import _ from "lodash";
import { createRoot } from 'react-dom/client';
import GridInvoiceSummaryCustomComponent from "./components/GridInvoiceSummaryCustomComponent";

// Product Imports
import settingsForm from "./GridInvoiceSummary.settingsForm";

export default class GridInvoiceSummary extends ReactComponent {
    /**
     * This function tells the form builder about your component. It's name, icon and what group it should be in.
     *
     * @returns {{title: string, icon: string, group: string, documentation: string, weight: number, schema: *}}
     */
    static get builderInfo() {
        return {
            title: "Grid Invoice Summary",
            icon: "table",
            group: "basic",
            documentation: "",
            weight: -10,
            schema: GridInvoiceSummary.schema()
        };
    }

    /**
     * This function is the default settings for the component. At a minimum you want to set the type to the registered
     * type of your component (i.e. when you call Components.setComponent('type', MyComponent) these types should match.
     *
     * @param sources
     * @returns {*}
     */
    static schema() {
        return ReactComponent.schema({
            type: "GridInvoiceSummary",
            label: "Default Label"
        });
    }

    /*
     * Defines the settingsForm when editing a component in the builder.
     */
    static editForm = settingsForm;

    rootEle = '';
    /**
     * This function is called when the DIV has been rendered and added to the DOM. You can now instantiate the react component.
     *
     * @param DOMElement
     * #returns ReactInstance
     */

    attachReact(element) {

        this.rootEle = createRoot(element);
        return this.rootEle.render(
            <GridInvoiceSummaryCustomComponent
                component={this.component} // These are the component settings if you want to use them to render the component.
                value={this.data} // The starting value of the component.
                onChange={this.updateValue} // The onChange event to call when the value changes.
                isBuilder={this.option?.attachMode === 'builder'}
            />

        );
    }

    /**
     * Automatically detach any react components.
     *
     * @param element
     */
    detachReact(element) {
        if (element) {
            this.rootEle.unmount();
        }
    }
}
