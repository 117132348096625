// eslint-disable-next-line import/named
// Package Imports
import { BaseKey, MetaQuery } from '@refinedev/core';

// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type ManyParams = {
  apiUrl: string;
  resource: string;
  ids?: BaseKey[];
  meta: MetaQuery;
};

const getMany = async ({ resource, ids, meta, apiUrl }: ManyParams) => {
  let url = `${apiUrl}/${resource}`;
  const name = meta?.name;
  if (name) {
    ids.forEach((id: number) => {
      url += `${url.includes('?') ? '&' : '?'}${name}.in=${id}`;
    });
  } else {
    url += `?id.in=${ids.join(',')}`;
  }

  const { data, headers } = await axiosInstance.get(url);

  return {
    data,
    headers,
  };
};

export default getMany;
