import baseEditForm from "formiojs/components/_classes/component/Component.form";
import A8Utils from "../../utils/A8Utils";

export default (...extend) => {
    return baseEditForm(
        [
            {
                key: "display",
                components: [
                    {
                        // You can ignore existing fields.
                        key: "placeholder",
                        ignore: true
                    },
                    {
                        // Or add your own. The syntax is form.io component definitions.
                        type: "textfield",
                        input: true,
                        label: "My Custom Setting",
                        weight: 12,
                        key: "myCustomSetting" // This will be available as component.myCustomSetting
                    }
                ]
            },
            {
                key: "data",
                components: [
                    {
                        type: "textfield",
                        input: true,
                        label: "Grid Action Buttons Id",
                        weight: 12,
                        key: "gridActionButtonsId"
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Action Buttons  List',
                        key: 'actionButtonsList',
                        weight: -100,
                        components: [
                            {
                                label: 'Button Label',
                                key: 'buttonLabel',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Button Name',
                                key: 'buttonName',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Action',
                                key: 'action',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Button Type',
                                key: 'buttonType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Default',
                                            value: 'default'
                                        },
                                        {
                                            label: 'Primary',
                                            value: A8Utils.formBuilderFieldNames.primary
                                        },
                                        {
                                            label: 'Danger',
                                            value: A8Utils.formBuilderFieldNames.danger
                                        },
                                        {
                                            label: 'Dashed',
                                            value: 'dashed'
                                        },
                                        {
                                            label: 'Text',
                                            value: 'text'
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Positive Action Button Components List',
                        key: 'positiveActionButtonComponentsList',
                        weight: -99,
                        components: [
                            {
                                label: 'Component Label',
                                key: 'componentLabel',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Component Form Name Parameter',
                                key: 'compoentFormNameParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Component Field Type',
                                key: 'compoentFieldType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Input',
                                            value: A8Utils.formBuilderFieldNames.input
                                        },
                                        {
                                            label: 'Select',
                                            value: A8Utils.formBuilderFieldNames.select
                                        },
                                        {
                                            label: 'Text',
                                            value: A8Utils.formBuilderFieldNames.text
                                        },
                                        {
                                            label: 'Upload',
                                            value: A8Utils.formBuilderFieldNames.upload
                                        }
                                    ]
                                }
                            },
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Negative Action Button Components List',
                        key: 'negativeActionButtonComponentsList',
                        weight: -99,
                        components: [
                            {
                                label: 'Component Label',
                                key: 'componentLabel',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Component Form Name Parameter',
                                key: 'compoentFormNameParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Component Field Type',
                                key: 'componentFieldType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Input',
                                            value: A8Utils.formBuilderFieldNames.input
                                        },
                                        {
                                            label: 'Select',
                                            value: A8Utils.formBuilderFieldNames.select
                                        },
                                        {
                                            label: 'Text',
                                            value: A8Utils.formBuilderFieldNames.text
                                        },
                                        {
                                            label: 'Upload',
                                            value: A8Utils.formBuilderFieldNames.upload
                                        }
                                    ]
                                }
                            },
                        ]
                    }
                ]
            },
            {
                key: "validation",
                components: []
            },
            {
                key: "api",
                components: [
                    {
                        label: 'Api Url Method',
                        key: 'apiUrlMethod',
                        input: true,
                        type: 'select',
                        data: {
                            values: [
                                {
                                    label: 'PUT',
                                    value: 'put'
                                },
                                {
                                    label: 'POST',
                                    value: 'post'
                                },
                                {
                                    label: 'GET',
                                    value: 'get'
                                },
                                {
                                    label: 'DELETE',
                                    value: 'delete'
                                },
                            ]
                        },
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Api Url",
                        weight: 1,
                        key: "apiUrl"
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Api Url Parameters List',
                        key: 'apiUrlParamsList',
                        weight: 2,
                        components: [
                            {
                                label: 'Api Url Parameter',
                                key: 'apiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Api Url Target Key',
                                key: 'apiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Api Url Target',
                                key: 'apiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [{
                                        label: 'Submission',
                                        value: 'submission'
                                    },]
                                },
                            },
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Properties',
                        key: 'propertiesList',
                        weight: 3,
                        components: [
                            {
                                label: 'Property Key',
                                key: 'propertyKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Property Value',
                                key: 'propertyValue',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Target Type',
                                key: 'targetType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: 'submission'
                                        },
                                        {
                                            label: 'Form Data',
                                            value: 'formData'
                                        },
                                        {
                                            label: 'Text Data',
                                            value: 'textData'
                                        },
                                    ]
                                },
                            },
                        ]
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Payload Parameters List',
                        key: 'payloadParametersList',
                        weight: 4,
                        components: [
                            {
                                label: 'Payload Parameter Key',
                                key: 'payloadParameterKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Payload Parameter Value',
                                key: 'payloadParameterValue',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Payload Parameter Target Type',
                                key: 'payloadParameterTargetType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Submission',
                                            value: 'submission'
                                        },
                                        {
                                            label: 'Form Data',
                                            value: 'formData'
                                        },
                                        {
                                            label: 'Text Data',
                                            value: 'textData'
                                        },
                                    ]
                                },
                            },
                            {
                                label: 'Button Type',
                                key: 'buttonType',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [
                                        {
                                            label: 'Default',
                                            value: 'default'
                                        },
                                        {
                                            label: 'Primary',
                                            value: A8Utils.formBuilderFieldNames.primary
                                        },
                                        {
                                            label: 'Danger',
                                            value: A8Utils.formBuilderFieldNames.danger
                                        },
                                        {
                                            label: 'Dashed',
                                            value: 'dashed'
                                        },
                                        {
                                            label: 'Text',
                                            value: 'text'
                                        },
                                    ]
                                },
                            }
                        ]
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Reasons Api Url",
                        weight: 5,
                        key: "reasonsApiUrl"
                    },
                    {
                        type: 'datagrid',
                        input: true,
                        label: 'Reasons Api Url Parameters List',
                        key: 'reasonsApiUrlParamsList',
                        weight: 6,
                        components: [
                            {
                                label: 'Reasons Api Url Parameter',
                                key: 'reasonsApiUrlParameter',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Reasons Api Url Target Key',
                                key: 'reasonsApiUrlTargetKey',
                                input: true,
                                type: 'textfield'
                            },
                            {
                                label: 'Reasons Api Url Target',
                                key: 'reasonsApiUrlTarget',
                                input: true,
                                type: 'select',
                                data: {
                                    values: [{
                                        label: 'Submission',
                                        value: A8Utils.formBuilderFieldNames.submission
                                    },]
                                },
                            },
                        ]
                    },
                    {
                        type: "textfield",
                        input: true,
                        label: "Reasons Api Url Response Data Key",
                        weight: 7,
                        key: "reasonsApiUrlResponseDataKey"
                    },
                ]
            },
            {
                key: "conditional",
                components: []
            },
            {
                key: "logic",
                components: []
            }
        ],
        ...extend
    );
};