// Package Imports
import { MoneyCollectOutlined } from '@ant-design/icons';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const AccountingResource: ResourceItem = {
  name: resourceName.accounting,
  identifier: ModuleIdentifierConst.ACCOUNTING,
  list: `${resourceName.accounting}`,
  options: { label: 'Accounting', icon: <MoneyCollectOutlined /> },
};

export default AccountingResource;
