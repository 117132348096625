// Package Imports
import { DownloadOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import PrivateRouteConst from 'core/common/privateRouteConstant';

const MasterFileDownloadResource: ResourceItem = {
  name: resourceName.masterFileDownload,
  identifier: PrivateRouteConst.MASTER_FILE_DOWNLOAD,
  list: `${resourceName.masterFileDownload}`,
  meta: {
    label: 'Download',
    icon: <DownloadOutlined />,
    parent: resourceName.maintainence,
  },
};

export default MasterFileDownloadResource;
