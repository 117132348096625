// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type UpdateParams = {
  apiUrl: string;
  resource: string;
  id: string | number;
  variables: Record<string, unknown>;
};

const update = async ({ resource, id, variables, apiUrl }: UpdateParams) => {
  const url = `${apiUrl}/${resource}/${id}/`;
  const { data } = await axiosInstance.put(url, variables);
  return {
    data,
  };
};

export default update;
