// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type CreateParams = {
  apiUrl: string;
  resource: string;
  variables: Record<string, unknown>;
};

const create = async ({ resource, variables, apiUrl }: CreateParams) => {
  const url = `${apiUrl}/${resource}/`;
  const { data } = await axiosInstance.post(url, variables);
  return {
    data,
  };
};

export default create;
