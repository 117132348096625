// package imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
// import PrivateRouteConst from 'core/common/privateRouteConstant';
// import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const Charts = lazy(() => import('pages/charts/charts'));
const ChartRoute = (
  <Route
    index
    path={routeName.chart}
    element={
      // <PrivateRoute name={PrivateRouteConst.CHART_VIEW}>
      <Suspense
        fallback={
          <div>
            <Loading />
          </div>
        }
      >
        <Charts />
      </Suspense>
      // </PrivateRoute>
    }
  />
);

export default ChartRoute;
