// Project Imports
import apiEndpoint from 'core/common/apiEndPoints';
import axiosInstance from 'providers/axiosInstance/axiosInstance';
import logger from 'core/utils/logger';

const fetchRoles = async (
  setUserRoleList: React.Dispatch<React.SetStateAction<any[]>>
): Promise<void> => {
  try {
    const apiUrl = `${process.env.REACT_APP_USER_API_URL}/${apiEndpoint.userRoles}/?page=1&page_size=10000`;

    console.log(apiUrl, 'REACT_APP_API_URL_ROLE');
    const response = await axiosInstance.get(
      //  Temporary for getting all org - ?page=1&page_size=100
      `${apiUrl}`
    );
    const responseData = (response.data as any)?.results;
    const data = responseData.map((item) => ({
      label: item.role_name,
      value: item.role_id,
    }));
    await setUserRoleList(data);

    if (responseData)
      logger('User roles program details data :: ', responseData);
  } catch (error) {
    if (error?.response?.data)
      logger('User roles program details error :: ', error?.response?.data);
  }
};

export default fetchRoles;
