// Project Imports
import routeName from 'core/common/routeName';

const authenticateFailed = {
  authenticated: false,
  logout: true,
  redirectTo: routeName.error,
  error: {
    message: 'Check failed',
    name: 'Token not found',
  },
};

export default authenticateFailed;
