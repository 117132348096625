// Project Imports
import axiosInstance from 'providers/axiosInstance/axiosInstance';

type DeleteParams = {
  apiUrl: string;
  resource: string;
  id?: number;
};

const deleteOne = async ({ resource, id, apiUrl }: DeleteParams) => {
  const url = `${apiUrl}/${resource}/${id}/`;
  const { data } = await axiosInstance.delete(url);
  return {
    data,
  };
};

export default deleteOne;
