import ContractGrid from "./ContractGrid";
import GridActionButtonsGroup from "./GridActionButtonsGroup";
import GridSummaryCard from "./GridSummaryCard";
import GridInvoiceSummary from "./GridInvoiceSummary"
import ExpandableContractGrid from "./ExpandableContractGrid";

export const FormioCustomComponent = {
    ContractGrid,
    GridSummaryCard,
    GridInvoiceSummary,
    GridActionButtonsGroup,
    ExpandableContractGrid
}
export default FormioCustomComponent;

