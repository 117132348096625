// package imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// project imports
import routeName from 'core/common/routeName';
import PrivateRouteConst from 'core/common/privateRouteConstant';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const PayoutInitialization = lazy(
  () => import('pages/payoutInitialization/PayoutInitialization')
);
const PayoutInitializationRoute = (
  <Route
    path={routeName.payoutInitialization}
    element={
      <PrivateRoute name={PrivateRouteConst.START_PAYOUT}>
        <Suspense
          fallback={
            <div>
              <Loading />
            </div>
          }
        >
          <PayoutInitialization />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default PayoutInitializationRoute;
