import moment from 'moment';

function generateMonthlyList() {
  const monthlyData = [];
  for (let i = 0; i < 12; i += 1) {
    const monthName = moment().month(i).format('MMMM');
    monthlyData.push(monthName);
  }
  return monthlyData;
}

function generatePeriodList(monthList, periods) {
  const periodList = [];
  const periodLength = Math.floor(monthList.length / periods);
  for (let i = 0; i < periods; i += 1) {
    const startIdx = i * periodLength;
    const endIdx = (i + 1) * periodLength - 1;
    const periodLabel = `${monthList[startIdx].toUpperCase()} - ${monthList[
      endIdx
    ].toUpperCase()}`;
    const periodValue = `${monthList[startIdx].toLowerCase()}-${monthList[
      endIdx
    ].toLowerCase()}`;
    periodList.push({ value: periodValue, label: periodLabel });
  }
  return periodList;
}

function generateFullMonthList(monthList) {
  const fullMonthList = monthList.map((month) => ({
    value: month.toLowerCase(),
    label: month.toUpperCase(),
  }));
  return fullMonthList;
}
function getYearsList() {
  const currentDate = moment();
  const sixMonthsAgo = moment(currentDate).subtract(6, 'months');
  const startYear = sixMonthsAgo.year();
  const endYear = currentDate.year();
  const years = [];
  years.push({ label: startYear.toString(), value: startYear });
  if (startYear !== endYear) {
    years.push({ label: endYear.toString(), value: endYear });
  }

  return years;
}

function getLastFiveYears() {
  const currentDate = moment();
  const currentYear = currentDate.year();
  const years = [];

  for (let i = 0; i < 5; i += 1) {
    const year = currentYear - i;
    years.push({ value: year, label: year.toString() });
  }
  return years;
}

const monthList = generateMonthlyList();
const yearsList = getYearsList();
const fiveYearsList = getLastFiveYears();
const monthlyList = generateFullMonthList(monthList);
const quarterlyList = generatePeriodList(monthList, 4);
const halfYearlyList = generatePeriodList(monthList, 2);

export {
  quarterlyList,
  halfYearlyList,
  monthlyList,
  yearsList,
  monthList,
  fiveYearsList,
};
