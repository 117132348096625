// Package Imports
import React, { Suspense, lazy } from 'react';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';
import resourceName from 'core/common/resourceName';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

// Lazy-loaded Component
const Profile = lazy(() => import('pages/profile/profile'));

const ProfileRoute = (
  <Route
    path={routeName.profile}
    element={
      <PrivateRoute name={resourceName.profile}>
        <Suspense fallback={<Loading />}>
          <Profile />
        </Suspense>
      </PrivateRoute>
    }
  />
);

export default ProfileRoute;
