const invoiceSummaryDataDummy = {
    "invoiceSummary": {
        "systemInvoice": {
            "id": "ABCD/1234/DE56"
        },
        "agencyInvoice": "",
        "amount": 2400,
        "state": "Maharashtra",
        "invoiceDate": "2024-03-21 02:47:46",
        "payoutMonth": "April",
        "utrNumber": "19359",
        "paymentAmount": 2450,
        "paymentDate": "2024-04-30 12:09:20"
    }
}

export { invoiceSummaryDataDummy }