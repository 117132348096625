// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const Augment8DashboardResource: ResourceItem = {
  name: resourceName.augment8Dashboard,
  identifier: resourceName.augment8Dashboard,
  list: `${resourceName.augment8Dashboard}`,
  options: { label: 'Augment8 Dashboard' },
};

export default Augment8DashboardResource;
