// Package Imports
import { FileAddOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import PrivateRouteConst from 'core/common/privateRouteConstant';

const InputFileUploadResource: ResourceItem = {
  name: resourceName.inputFileUpload,
  identifier: PrivateRouteConst.INPUT_FILE_UPLOAD,
  list: `${resourceName.inputFileUpload}`,
  meta: {
    label: 'Upload',
    icon: <FileAddOutlined />,
    parent: resourceName.fileManagement,
  },
};

export default InputFileUploadResource;
