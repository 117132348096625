// Package Imports
import React from 'react';
import { DatePicker, Form } from 'antd';

// Project Imports
import { DateInputProps } from 'components/date/type';
// import ConfigStyle from 'core/constants/ConfigStyle';

const DateInput = ({
  label,
  name,
  style,
  disabled,
  required,
  message,
  placeholder,
  format,
  getValueProps,
  showTime,
  use12Hours,
}: DateInputProps) => (
  <div
    className={
      disabled
        ? 'floating-disabled relative custom-border'
        : 'relative custom-border'
    }
  >
    <label className="floating-label">
      {label} {required ? <span className="a8-error">*</span> : null}
    </label>
    <Form.Item
      rules={[{ required, message }]}
      style={style}
      name={[name]}
      getValueProps={getValueProps}
    >
      <DatePicker
        className="a8-date-picker"
        disabled={disabled}
        placeholder={placeholder}
        format={format}
        showTime={showTime}
        use12Hours={use12Hours}
      />
    </Form.Item>
  </div>
);

export default DateInput;
