// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import routeName from 'core/common/routeName';
// import PrivateRouteConst from 'core/common/privateRouteConstant';
// import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const ChartMappingList = lazy(
  () => import('pages/chartMapping/chartMappingList')
);
const ChartMappingCreate = lazy(
  () => import('pages/chartMapping/chartMappingCreate')
);
const ChartMappingEdit = lazy(
  () => import('pages/chartMapping/chartMappingEdit')
);
const ChartMappingShow = lazy(
  () => import('pages/chartMapping/chartMappingShow')
);

const ChartMappingRoute = (
  <Route path={routeName.chartMapping}>
    <Route
      index
      element={
        // <PrivateRoute
        //   name={[
        //     PrivateRouteConst.FREQUENCY_VIEW,
        //     PrivateRouteConst.SELF_FREQUENCY_VIEW,
        //   ]}
        // >
        <Suspense fallback={<Loading />}>
          <ChartMappingList />
        </Suspense>
        // </PrivateRoute>
      }
    />
    <Route
      path="create"
      element={
        // <PrivateRoute name={[PrivateRouteConst.CREATE_FREQUENCY]}>
        <Suspense fallback={<Loading />}>
          <ChartMappingCreate />
        </Suspense>
        // </PrivateRoute>
      }
    />
    <Route
      path="edit/:id"
      element={
        // <PrivateRoute
        //   name={[
        //     PrivateRouteConst.FREQUENCY_EDIT,
        //     PrivateRouteConst.SELF_FREQUENCY_EDIT,
        //   ]}
        // >
        <Suspense fallback={<Loading />}>
          <ChartMappingEdit />
        </Suspense>
        // </PrivateRoute>
      }
    />
    <Route
      path="show/:id"
      element={
        // <PrivateRoute
        //   name={[
        //     PrivateRouteConst.FREQUENCY_VIEW,
        //     PrivateRouteConst.SELF_FREQUENCY_DELETE,
        //   ]}
        // >
        <Suspense fallback={<Loading />}>
          <ChartMappingShow />
        </Suspense>
        // </PrivateRoute>
      }
    />
  </Route>
);

export default ChartMappingRoute;
