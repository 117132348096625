// Package Imports
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import detector from 'i18next-browser-languagedetector';

// eslint-disable-next-line @typescript-eslint/no-floating-promises, import/no-named-as-default-member
i18n
  .use(Backend)
  .use(detector)
  .use(initReactI18next)
  .init({
    supportedLngs: ['en', 'de', 'es', 'hi'],
    backend: {
      loadPath: '/locales/{{lng}}.json',
    },
    fallbackLng: ['en', 'de', 'es', 'hi'],
  });

export default i18n;
