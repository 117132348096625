// Package Imports
import { FileSearchOutlined } from '@ant-design/icons';

// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';

const PayoutHistoryResource: ResourceItem = {
  name: resourceName.payoutHistory,
  identifier: PrivateRouteConst.PAYOUT_HISTORY,
  list: `${resourceName.payoutHistory}`,
  meta: {
    label: '',
    icon: <FileSearchOutlined />,
    parent: resourceName.payout,
  },
};

export default PayoutHistoryResource;
