// Package Imports
import { ProjectOutlined } from '@ant-design/icons';

// Project Imports
import resourceName from 'core/common/resourceName';
import ResourceItem from 'interfaces/resource';
import ModuleIdentifierConst from 'core/common/moduleIdentifier';

const Maintainence: ResourceItem = {
  name: resourceName.maintainence,
  identifier: ModuleIdentifierConst.MAINTAINENCE,
  options: {
    label: 'Maintainence',
    icon: <ProjectOutlined />,
  },
};

export default Maintainence;
