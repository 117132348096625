// Package Imports
import React from 'react';
import { ErrorComponent } from '@refinedev/antd';
import { Route } from 'react-router-dom';

// Project Imports
import routeName from 'core/common/routeName';

const paths = ['*', routeName.error];

const ErrorRoute = (
  <>
    {paths.map((path, index) => (
      <Route key={index} path={path} element={<ErrorComponent />} />
    ))}
  </>
);

export default ErrorRoute;
