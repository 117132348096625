// Package Imports
import React from 'react';
import { useKeycloak } from '@react-keycloak/web';
import '@refinedev/antd/dist/reset.css';
import { type AuthBindings } from '@refinedev/core';

// Project Imports
import 'App.css';
import login from 'providers/apiProviders/login';
import logout from 'providers/apiProviders/logout';
import authError from 'providers/apiProviders/authError';
import getIdentity from 'providers/apiProviders/getIdentity';
import authenticateFailed from 'core/constants/AuthenticateFailed';
import Initiate from 'Initiate';
import { useTokenContext } from 'contexts/resource/ResourceContext';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Components } from 'react-formio';
import FormioCustomComponent from 'components/modules/formio-custom-components-package/custom';
import Loading from 'components/loading/loading';

Components.setComponents(FormioCustomComponent);

const App = () => {
  const { setToken, setRoles, loading } = useTokenContext();
  const { keycloak, initialized } = useKeycloak();

  const check = async () => {
    try {
      const { token } = keycloak;
      // if (token) {
      //   setToken(token);

      //   return {
      //     authenticated: true,
      //   };
      // }pages.error.info
      if (token) {
        console.log(keycloak?.realmAccess?.roles, 'keycloak');
        setToken(token);
        localStorage.setItem('token', token);
        localStorage.setItem('tokenType', 'Bearer');
        setRoles(keycloak?.realmAccess?.roles);
        // setPermissionsFetched(true);
        return {
          authenticated: true,
        };
      }

      return authenticateFailed;
    } catch (error) {
      return authenticateFailed;
    }
  };

  const authProvider: AuthBindings = {
    login,
    logout,
    onError: authError,
    check,
    getPermissions: async () => null,
    getIdentity,
  };

  if (!initialized || loading) {
    return <Loading />;
  }
  return <Initiate authProvider={authProvider} />;
};

export default App;
