const formatCurrency = (data) => {

    let number = typeof data === "string" ? Number(data) : data;

    let formatedNumber = number.toLocaleString('en-IN', {
        maximumFractionDigits: 2,
        style: 'currency',
        currency: 'INR'
    });

    return formatedNumber;
}

export default formatCurrency