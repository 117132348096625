// Package Imports
import { Route } from 'react-router-dom';
import React, { Suspense, lazy } from 'react';
// Project Imports
import PrivateRouteConst from 'core/common/privateRouteConstant';
import routeName from 'core/common/routeName';
import PrivateRoute from 'core/utils/PrivateRoute';
import Loading from 'components/loading/loading';

const InputFileUpload = lazy(
  () => import('pages/inputFileUpload/inputFileUpload')
);
const InputFileUploadRoute = (
  <Route path={routeName.inputFileUpload}>
    <Route
      index
      element={
        <PrivateRoute name={PrivateRouteConst.INPUT_FILE_UPLOAD}>
          <Suspense
            fallback={
              <div>
                <Loading />
              </div>
            }
          >
            <InputFileUpload />
          </Suspense>
        </PrivateRoute>
      }
    />
  </Route>
);

export default InputFileUploadRoute;
